var en = require("./translations.en.json");
var de = require("./translations.de.json");
var fr = require("./translations.fr.json");
var it = require("./translations.it.json");

const i18n = {
  translations: {
    en: en.i18n,
    de: de.i18n,
    fr: fr.i18n,
    it: it.i18n,
  },
  defaultLang: "de",
  useBrowserDefault: false,
  languageDataStore: "query",
};

module.exports = i18n;
