import { create } from 'zustand';
import { configSchema, Config, megabyte } from '../_config/schema';

// Type for the store
type ConfigStore = {
  config: Config | null;
  fetchConfig: () => Promise<Config>;
};

// Transform function to handle type conversions
const transformConfig = (rawConfig: any): Config => {
  return {
    ...rawConfig,
    general: {
      ...rawConfig.general,
      defaultToGravatarBool:
        (rawConfig.general.defaultToGravatarBool || '').toLowerCase() ===
        'true',
      maxSingleFileSizeMb:
        Number(rawConfig.general.maxSingleFileSizeMb) * megabyte ||
        2 * megabyte,
      maxTotalFilesSizeMb:
        Number(rawConfig.general.maxTotalFilesSizeMb) * megabyte ||
        10 * megabyte,
      maxReplyTextLen: Number(rawConfig.general.maxReplyTextLen) || 15000,
    },
  };
};

export const useConfigStore = create<ConfigStore>((set) => ({
  config: null,
  fetchConfig: async () => {
    const response = await fetch('/config.json');

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const rawConfig = await response.json();

    const validatedConfig = configSchema.parse(transformConfig(rawConfig));

    set({ config: validatedConfig });

    return validatedConfig;
  },
}));
