import { ExternalLinkIcon } from '@vereign/ui';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import React from 'react';
import { twMerge } from 'tailwind-merge';

interface LinkProps extends NextLinkProps {
  external?: boolean;
  children: React.ReactNode;
  className?: string;
  target?: React.HTMLAttributeAnchorTarget;
}

function Link({
  children,
  external,
  className,
  target = '_self',
  ...restProps
}: LinkProps) {
  return (
    <NextLink
      target={external ? '_blank' : target}
      className={twMerge(
        'inline-block text-primary underline',
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring',
        'rounded-md ring-offset-background focus-visible:ring-offset-2',
        className,
      )}
      {...restProps}
    >
      {children}
      {external && (
        <ExternalLinkIcon className="ml-2 inline-block h-4  w-4 fill-primary" />
      )}
    </NextLink>
  );
}

export default Link;
