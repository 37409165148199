import { z } from 'zod';

/* istanbul ignore file */
export const kilobyte = Math.pow(2, 10);
export const megabyte = Math.pow(2, 20);
export const gigabyte = Math.pow(2, 30);

const apiPathSchema = z.string();

const filesSchema = z.object({
  delete: apiPathSchema,
  upload: apiPathSchema,
  send: apiPathSchema,
  get: apiPathSchema,
});

const phoneSchema = z.object({
  update: apiPathSchema,
  verify: apiPathSchema,
  sms: apiPathSchema,
});

const authSchema = z.object({
  baseUrl: z.string().url(),
  config: apiPathSchema,
  logout: apiPathSchema,
  login: apiPathSchema,
  session: apiPathSchema,
  decrypt: apiPathSchema,
  files: filesSchema,
  phone: phoneSchema,
});

const ipfsSchema = z.object({
  baseUrl: z.string().url(),
  getPath: apiPathSchema,
});

const avatarSchema = z.object({
  baseUrl: z.string(),
  getPath: apiPathSchema,
  dnsLookupPath: z.string().url(),
});

const generalSchema = z.object({
  privacyPolicy: z.string().url(),
  label: z.string().url(),
  support: z.string().url(),
  defaultToGravatarBool: z.boolean(),
  maxSingleFileSizeMb: z.number().positive(),
  maxTotalFilesSizeMb: z.number().positive(),
  maxReplyTextLen: z.number().positive(),
});

export const configSchema = z.object({
  general: generalSchema,
  auth: authSchema,
  ipfs: ipfsSchema,
  avatar: avatarSchema,
});

export type Config = z.infer<typeof configSchema>;
