import React, { useEffect } from 'react';
import { useConfigStore } from '../_stores/AppConfigStore';
import { Config } from '../_config/schema';

type WithConfigProps = {
  config: Config;
};

export function withConfig<P extends WithConfigProps = WithConfigProps>(
  WrappedComponent: React.ComponentType<P>,
) {
  return function ConfigWrapper(props: Omit<P, keyof WithConfigProps>) {
    const { config, fetchConfig } = useConfigStore();

    useEffect(() => {
      if (!config) {
        fetchConfig();
      }
    }, [config, fetchConfig]);

    return <WrappedComponent {...(props as P)} config={config} />;
  };
}
